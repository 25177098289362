/* Visor Img React */
.VisorImgReactComponent {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.75);
	top: 0;
	left: 0;
	z-index: 5000;
	transition: 0.5s;

	.VisorImgReactComponent__options {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
		z-index: 200;
	}

	.VisorImgReactComponent__download {
		margin-right: 100px;
	}

	.VisorImgReactComponent__controlls {
		position: absolute;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 0px 10%;
		margin-top: 30px;
	}

	.VisorImgReactComponent__visor {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100%;
	}
	
	.imgShow {
		max-height: 80vh;
		max-width: 90vw;
	}
}

.imagePreview {
	max-width: 110px;
	max-height: 100px;
	min-width: 70px;
	min-height: 60px;
	cursor: pointer;
	transition: .5s;
	
	&:hover {
		transform: scale(1.2);
	}
}

.imagePreview__More {
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.4);
	width: 100px;
	height: 100px;
	margin-bottom: 10px;
	cursor: pointer;
}